<template>
  <b-modal
    id="compose-mail"
    :visible="shallShowEmailComposeModal"
    title="New Email"
    footer-class="d-flex justify-content-between"
    body-class="p-2"
    size="lg"
    no-fade
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ serviceForm.extendedProps ? serviceForm.extendedProps.customerName : '' }} {{ serviceForm.extendedProps ? serviceForm.extendedProps.customerContact : '' }} - Checked in without full payment
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <div
          id="quill-toolbar"
          class="d-flex border-bottom-0"
        >
          <!-- Add a bold button -->
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-align" />
          <button class="ql-link" />
        </div>
        <div class="footer_item">
          <b-button
            variant="primary"
            :disabled="!composeData.to || !composeData.subject || !composeData.message"
            @click="sendEmail"
          >
            <span class="align-middle">Send</span>
          </b-button>
        </div>
      </div>
    </template>

    <!-- Modal: Body -->
    <b-form>
      <!-- Field: To -->
      <div class="compose-mail-form-field">
        <label
          for="email-to"
          class="form-label"
        >To: </label>
        <!-- <b-form-input
          id="email-to"
          v-model="composeData.to"
        /> -->
        <b-form-tags
          v-model="composeData.to"
          input-id="tags-state-event"
          :tag-validator="validator"
          placeholder="Enter email"
          separator=" "
        />
        <!-- <span
          class="cursor-pointer"
          @click="showCcField=!showCcField"
        >Cc</span> -->
        <!-- <span
          class="ml-1 cursor-pointer"
          @click="showBccField=!showBccField"
        >Bcc</span> -->
      </div>

      <!-- Field: Cc (Hidden Initially) -->
      <div
        class="compose-mail-form-field"
      >
        <label for="email-cc">CC: </label>
        <b-form-tags
          v-model="composeData.cc"
          input-id="tags-state-event"
          :tag-validator="validator"
          placeholder="Enter email"
          separator=" "
        />
        <!-- <v-select
          v-model="composeData.cc"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          class="flex-grow-1 email-cc-selector"
          :close-on-select="false"
          :options="emailToOptions"
          :reduce="name => name.email"
          input-id="email-cc"
        >
          <template #option="{ email }">
            <span class=""> {{ email }}</span>
          </template>

          <template #selected-option="{ email }">
            <span class=""> {{ email }}</span>
          </template>
        </v-select> -->
      </div>

      <!-- Field: Bcc (Hidden Initially) -->
      <!-- <div
        v-show="showBccField"
        class="compose-mail-form-field"
      >
        <label for="email-bcc">Bcc </label>
        <v-select
          v-model="composeData.bcc"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          class="flex-grow-1 email-bcc-selector"
          :close-on-select="false"
          :options="emailToOptions"
          :reduce="name => name.email"
          input-id="email-bcc"
        >
          <template #option="{ email }">
            <span class=""> {{ email }}</span>
          </template>

          <template #selected-option="{ email }">
            <span class=""> {{ email }}</span>
          </template>
        </v-select>
      </div> -->

      <!-- Field: Subject -->
      <div class="compose-mail-form-field">
        <label for="email-subject">Subject: </label>
        <b-form-input
          id="email-subject"
          v-model="composeData.subject"
        />
      </div>

      <!-- Field: Message - Quill Editor -->
      <div class="message-editor">
        <quill-editor
          id="quil-content"
          v-model="composeData.message"
          :options="editorOption"
        />
      </div>
    </b-form>

  </b-modal>
</template>

<script>
import {
  BForm, BFormInput, BButton, BFormTags,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
// import vSelect from 'vue-select'
import axios from '@axios'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BForm,
    BFormInput,
    BButton,
    BFormTags,

    // 3rd Party
    quillEditor,
    // vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    serviceForm: {
      type: Object,
      required: true,
    },
  },
  watch: {
    serviceForm: {
      immediate: true,
      handler(val) {
        if (val.extendedProps) {
          this.composeData.subject = `${val.extendedProps.customerName} ${val.extendedProps.customerContact} - Checked in without full payment`
          this.composeData.message = `<p>Dear Finance Dept,<br>The above mentioned customer, ${val.extendedProps.customerName}, requested for a full payment upon check in.</p><br><p>The following are the case details:<br>Service Form No: ${val.extendedProps.formStringID}<br>Agent: ${val.extendedProps.agentName}, ${val.extendedProps.agentCode}<br>Lot No: ${val.extendedProps.deceasedAngling}<br><br>File No: ${val.extendedProps.fileNo}<br>Outstanding Amount: $&nbsp;__________ </p><br><p>We will monitor and contact his agent, ${val.extendedProps.agentName} to settle the outstanding payment matters.<br>Thank you.<br><br>Regards</p><p>${val.extendedProps.dutyOfficer}, via Nirvana App</p>`
        }
      },
    },
  },
  methods: {
    validator(tag) {
      return this.validateEmail(tag)
    },
    validateEmail(inputText) {
      // eslint-disable-next-line no-useless-escape
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (inputText.match(mailformat)) {
        return true
      }
      return false
    },
  },
  setup(props, { emit }) {
    const composeData = ref({
      to: [],
      subject: '',
      message: '',
      cc: [],
    })
    const showCcField = ref(false)
    const showBccField = ref(false)
    const subject = ref('')
    const message = ref('')
    const toast = useToast()

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Message',
    }

    const toEmail = process.env.VUE_APP_FINANCE_EMAIL.split(',')
    const ccEmail = process.env.VUE_APP_FINANCE_EMAIL_CC.split(',')
    const timezone = process.env.VUE_APP_TIME_ZONE

    composeData.value.to = toEmail
    composeData.value.cc = ccEmail
    if (props.serviceForm.extendedProps) {
      composeData.value.subject = `${props.serviceForm.extendedProps.customerName} ${props.serviceForm.extendedProps.customerContact} - Checked in without full payment`
      composeData.value.message = `<p>Dear Finance Dept,<br>The above mentioned customer, ${props.serviceForm.extendedProps.customerName}, requested for a full payment upon check in.</p><br><p>The following are the case details:<br>Service Form No: ${props.serviceForm.extendedProps.formStringID}<br>Agent: ${props.serviceForm.extendedProps.agentName}, ${props.serviceForm.extendedProps.agentCode}<br>Lot No: ${props.serviceForm.extendedProps.deceasedAngling}<br><br>File No: ${props.serviceForm.extendedProps.fileNo}<br>Outstanding Amount: $&nbsp;__________ </p><br><p>We will monitor and contact his agent, ${props.serviceForm.extendedProps.agentName} to settle the outstanding payment matters.<br>Thank you.<br><br>Regards</p><p>${props.serviceForm.extendedProps.dutyOfficer}, via Nirvana App</p>`
    }

    // composeData.value.cc

    const sendEmail = () => {
      // composeData.value = {}
      // console.log(composeData.value)

      axios

        .post('operation/service-forms/send-email', { composeData: composeData.value, serviceForm: props.serviceForm.extendedProps.serviceFormID })
        .then(response => {
          const updatedEventData = {

            id: props.serviceForm.extendedProps.serviceRequestID,
            extendedProps: {
              emailDate: moment(response.data.data.emailDate).utcOffset(timezone).format('DD/MM/YYYY, hh:mma'),
            },
          }
          const propsToUpdate = []
          const extendedPropsToUpdate = ['emailDate']
          emit('updateEventInCalendar', updatedEventData, propsToUpdate, extendedPropsToUpdate)
          emit('update:shallShowEmailComposeModal', false)
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      emit('update:shall-show-email-compose-modal', false)
    }

    const discardEmail = () => {
      // composeData.value = {}
      emit('update:shall-show-email-compose-modal', false)
    }

    return {
      composeData,
      editorOption,
      showCcField,
      showBccField,
      subject,
      message,

      // Email actions
      sendEmail,
      discardEmail,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }

  .compose-mail-form-field {
    display: flex;
  }
}
</style>
